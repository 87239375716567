import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setUserDetails } from '../../../toolkitRedux/toolkitReducer';

const ProfileBusinesses = (props) => {
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	return (
		<div className="column is-8">
			<div className="account-box is-form is-footerless">
				<div className="form-head stuck-header">
					<div className="form-head-inner">
						<div className="left">
							<h3 className="font">თქვენი ორგანიზაციები</h3>
						</div>
						<div className="right">
							<div className="buttons">
								<button
									id="save-button"
									className="button h-button is-primary is-raised font is-rounded"
								>
									ახალი ორგანიზაციის დამატება
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="form-body">
					<div className="fieldset">
						<div className="setting-list">
							{userDetails.businesses?.map((data, i) => (
								<div className="setting-item profile-session">
									<div className="column is-8">
										<div className="profile-icon">
											<i className="lnil lnil-briefcase"></i>
										</div>
										<div className="profile-meta">
											<p className="font">
												{data.short_name} "{data.legal_name}"
											</p>
											<p className="font-p">{data.registration_id}</p>
										</div>
									</div>
									<div className="column is-4">
										<div className="profile-meta">
											<p>
												<button className="button h-button is-primary is-raised font is-rounded">
													გადართვა
												</button>
											</p>
											<p>
												<button className="button h-button is-primary is-raised font is-rounded">
													სიიდან ამოღება
												</button>
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileBusinesses;
