import React from 'react';



const PageContact = (props) => {
	
	return (

        <div id="user-profile" className="view-wrapper is-webapp" data-page-title="User Profile" data-naver-offset="214" data-menu-item="#home" data-mobile-item="#home-sidebar-menu-mobile">

            <div className="page-content-wrapper">
                <div className="page-content is-relative">

                    
                    <div className="page-content-inner">
		
        <div className="profile-wrapper">
                            <div className="profile-header has-text-centered">
                                <div className="h-avatar is-xl">
                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/8.jpg" alt="" data-user-popover="3"> */}
                                    {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/united-states-of-america.svg" alt=""> */}
                                </div>
                                <h3 className="title is-4 is-narrow is-thin">Erik Kovalsky</h3>
                                <p className="light-text">Hey everyone, Iam a product manager from New York and Iam looking for new
                                    opportunities in the software business.</p>
                                <div className="profile-stats">
                                    <div className="profile-stat">
                                        <i className="lnil lnil-users-alt"></i>
                                        <span>500+ Relations</span>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="profile-stat">
                                        <i className="lnil lnil-checkmark-circle"></i>
                                        <span>78 Projects</span>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="socials">
                                        <a><i aria-hidden="true" className="fab fa-facebook-f"></i></a>
                                        <a><i aria-hidden="true" className="fab fa-twitter"></i></a>
                                        <a><i aria-hidden="true" className="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="profile-body">
                                <div className="columns">
                                    <div className="column is-8">
                                        <div className="profile-card">
                                            <div className="profile-card-section">
                                                <div className="section-title">
                                                    <h4>About Me</h4>
                                                    <a><i className="lnil lnil-pencil"></i></a>
                                                </div>
                                                <div className="section-content">
                                                    <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing
                                                        elit. Falli igitur possumus. Praeteritis, inquit, gaudeo. Duo Reges:
                                                        constructio interrete. Idemne, quod iucunde? Quis enim redargueret? Non
                                                        semper, inquam; Quid nunc honeste dicit? Eam tum adesse, cum dolor omnis
                                                        absit; Negat enim summo bono afferre incrementum diem.</p>
                                                </div>
                                            </div>
                                            <div className="profile-card-section">
                                                <div className="section-title">
                                                    <h4>Experience</h4>
                                                    <a><i className="lnil lnil-pencil"></i></a>
                                                </div>
                                                <div className="section-content">
                                                    <div className="experience-wrapper">
                                                        <div className="experience-item">
                                                            {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/photo/demo/brands/airbnb.svg" alt=""> */}
                                                            <div className="meta">
                                                                <span className="dark-inverted">Airbnb HQ</span>
                                                                <span>
                                                                  <span>January 2018</span>
                                                                <i aria-hidden="true" className="fas fa-circle"></i>
                                                                <span>October 2020</span>
                                                                </span>
                                                                <span>Product Manager</span>
                                                            </div>
                                                        </div>
                                                        <div className="experience-item">
                                                            {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/photo/demo/brands/facebook.svg" alt=""> */}
                                                            <div className="meta">
                                                                <span className="dark-inverted">Facebook</span>
                                                                <span>
                                                                  <span>January 2018</span>
                                                                <i aria-hidden="true" className="fas fa-circle"></i>
                                                                <span>October 2020</span>
                                                                </span>
                                                                <span>Product Manager</span>
                                                            </div>
                                                        </div>
                                                        <div className="experience-item">
                                                            {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/photo/demo/brands/atlassian.svg" alt=""> */}
                                                            <div className="meta">
                                                                <span className="dark-inverted">Atlassian</span>
                                                                <span>
                                                                  <span>January 2018</span>
                                                                <i aria-hidden="true" className="fas fa-circle"></i>
                                                                <span>October 2020</span>
                                                                </span>
                                                                <span>Web Developer</span>
                                                            </div>
                                                        </div>
                                                        <div className="experience-item">
                                                            {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/photo/demo/brands/github.svg" alt=""> */}
                                                            <div className="meta">
                                                                <span className="dark-inverted">Github</span>
                                                                <span>
                                                                  <span>January 2018</span>
                                                                <i aria-hidden="true" className="fas fa-circle"></i>
                                                                <span>October 2020</span>
                                                                </span>
                                                                <span>Web Developer</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="more-button has-text-centered">
                                                        <a className="button h-button is-light">View More</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profile-card-section">
                                                <div className="section-title">
                                                    <h4>Languages</h4>
                                                    <a><i className="lnil lnil-pencil"></i></a>
                                                </div>
                                                <div className="section-content">
                                                    <div className="languages-wrapper">
                                                        <div className="languages-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/united-states-of-america.svg" alt=""> */}
                                                                <span className="languages-donut">100/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">English</span>
                                                                <span>Native Speaker, fluent</span>
                                                            </div>
                                                        </div>
                                                        <div className="languages-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/france.svg" alt=""> */}
                                                                <span className="languages-donut">80/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">French</span>
                                                                <span>Fluent, written and spoken</span>
                                                            </div>
                                                        </div>
                                                        <div className="languages-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/germany.svg" alt=""> */}
                                                                <span className="languages-donut">30/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">German</span>
                                                                <span>Beginner level</span>
                                                            </div>
                                                        </div>
                                                        <div className="languages-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/spain.svg" alt=""> */}
                                                                <span className="languages-donut">40/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Spanish</span>
                                                                <span>Beginner level</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="profile-card-section">
                                                <div className="section-title">
                                                    <h4>Skills</h4>
                                                    <a><i className="lnil lnil-pencil"></i></a>
                                                </div>
                                                <div className="section-content">
                                                    <div className="skills-wrapper">

                                                        <div className="skills-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/js.svg" alt=""> */}
                                                            </div>
                                                            <div className="skill-info">
                                                                <span className="dark-inverted">Javascript</span>
                                                                <span>7 years of experience</span>
                                                            </div>
                                                            <div className="people">
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake is-warning"
                                                                      data-user-popover="36">
                                                                      <span>BT</span>
                                                                    </span>
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/18.jpg" alt="" data-user-popover="7"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake is-info" data-user-popover="34">
                                                                      <span>JD</span>
                                                                    </span>
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/7.jpg" alt="" data-user-popover="0"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake">
                                                                      <span>38</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="skills-item">
                                                            <div className="icon-wrap has-icon">
                                                                <i className="lnil lnil-burger-alt"></i>
                                                            </div>
                                                            <div className="skill-info">
                                                                <span className="dark-inverted">Product Management</span>
                                                                <span>4 years of experience</span>
                                                            </div>
                                                            <div className="people">
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/21.jpg" alt="" data-user-popover="19"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake is-success"
                                                                      data-user-popover="37">
                                                                      <span>AT</span>
                                                                    </span>
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/39.jpg" alt="" data-user-popover="33"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/23.jpg" alt="" data-user-popover="21"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake">
                                                                      <span>27</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="skills-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/html5.svg" alt=""> */}
                                                            </div>
                                                            <div className="skill-info">
                                                                <span className="dark-inverted">Html 5</span>
                                                                <span>10 years of experience</span>
                                                            </div>
                                                            <div className="people">
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/38.jpg" alt="" data-user-popover="32"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/11.jpg" alt="" data-user-popover="13"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake is-h-purple"
                                                                      data-user-popover="35">
                                                                      <span>SC</span>
                                                                    </span>
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/13.jpg" alt="" data-user-popover="6"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake">
                                                                      <span>19</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="skills-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/css3.svg" alt=""> */}
                                                            </div>
                                                            <div className="skill-info">
                                                                <span className="dark-inverted">CSS 3</span>
                                                                <span>10 years of experience</span>
                                                            </div>
                                                            <div className="people">
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/21.jpg" alt="" data-user-popover="19"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake is-h-purple"
                                                                      data-user-popover="35">
                                                                      <span>SC</span>
                                                                    </span>
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/5.jpg" alt="" data-user-popover="9"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/7.jpg" alt="" data-user-popover="0"> */}
                                                                </div>
                                                                <div className="h-avatar is-small">
                                                                    <span className="avatar is-fake">
                                                                      <span>31</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="more-button has-text-centered">
                                                        <a className="button h-button is-light">View More</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="profile-card">
                                            <div className="profile-card-section no-padding">
                                                <div className="section-title">
                                                    <h4>Recommendations</h4>
                                                    <a><i className="lnil lnil-pencil"></i></a>
                                                    <a className="action-link">View All</a>
                                                </div>
                                                <div className="section-content">
                                                    <div className="recommendations-wrapper">

                                                        <div className="recommendations-item">
                                                            <div className="h-avatar is-large">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/5.jpg" alt="" data-user-popover="9"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/united-states-of-america.svg" alt=""> */}
                                                            </div>
                                                            <h3 className="dark-inverted">Project Manager</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                                                                multis malis affectus. Certe, nisi voluptatem tanti
                                                                aestimaretis.</p>
                                                            <div className="meta">
                                                                <span>Mary L.</span>
                                                                <span>September 3, 2020</span>
                                                            </div>
                                                        </div>

                                                        <div className="recommendations-item">
                                                            <div className="h-avatar is-large">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/18.jpg" alt="" data-user-popover="7"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/united-states-of-america.svg" alt=""> */}
                                                            </div>
                                                            <h3 className="dark-inverted">UI/UX Designer</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                                                                multis malis affectus. Certe, nisi voluptatem tanti
                                                                aestimaretis.</p>
                                                            <div className="meta">
                                                                <span>Esteban C.</span>
                                                                <span>September 9, 2020</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="column is-4">

                                        <div className="profile-card">
                                            <div className="profile-card-section no-padding">
                                                <div className="section-title">
                                                    <h4>Notifications</h4>
                                                    <div className="control">
                                                        <label className="form-switch is-success">
                                                            {/* <input type="checkbox" className="is-switch" checked> */}
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="section-content">
                                                    <div className="network-notifications">
                                                        <h3 className="dark-inverted">Notify Your Network?</h3>
                                                        <p>Enable or disable this setting to manage if your network should be
                                                            notified when you make changes to your profile.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profile-card">
                                            <div className="profile-card-section no-padding">
                                                <div className="section-title">
                                                    <h4>Tools</h4>
                                                    <a><i className="lnil lnil-pencil"></i></a>
                                                    <a className="action-link">View All</a>
                                                </div>
                                                <div className="section-content">

                                                    <div className="tools-wrapper">
                                                        <div className="tools-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/illustrator.svg" alt=""> */}
                                                                <span className="languages-donut">80/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Adobe Illustrator</span>
                                                                <span>Advanced level</span>
                                                            </div>
                                                        </div>

                                                        <div className="tools-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/photo/demo/brands/jira.svg" alt=""> */}
                                                                <span className="languages-donut">60/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Jira Software</span>
                                                                <span>Intermediate level</span>
                                                            </div>
                                                        </div>

                                                        <div className="tools-item">
                                                            <div className="icon-wrap">
                                                                {/* <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/photo/demo/brands/office.svg" alt=""> */}
                                                                <span className="languages-donut">95/100</span>
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">MS Office</span>
                                                                <span>Expert level</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="profile-card">
                                            <div className="profile-card-section no-padding">
                                                <div className="section-title">
                                                    <h4>Recent Views</h4>
                                                    <a className="action-link">View All</a>
                                                </div>
                                                <div className="section-content">

                                                    <div className="people-wrapper">

                                                        <a href="#" className="people-item">
                                                            <div className="h-avatar">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/25.jpg" alt="" data-user-popover="4"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/js.svg" alt=""> */}
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Melany W.</span>
                                                                <span>Web Developer</span>
                                                            </div>
                                                        </a>

                                                        <a href="#" className="people-item">
                                                            <div className="h-avatar">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/29.jpg" alt="" data-user-popover="25"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/python.svg" alt=""> */}
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Hakeem C.</span>
                                                                <span>Web Developer</span>
                                                            </div>
                                                        </a>

                                                        <a href="#" className="people-item">
                                                            <div className="h-avatar">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/38.jpg" alt="" data-user-popover="32"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/vuejs.svg" alt=""> */}
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Christie D.</span>
                                                                <span>Web Developer</span>
                                                            </div>
                                                        </a>

                                                        <a href="#" className="people-item">
                                                            <div className="h-avatar">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/28.jpg" alt="" data-user-popover="24"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/stacks/angular.svg" alt=""> */}
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Edouard F.</span>
                                                                <span>Software Engineer</span>
                                                            </div>
                                                        </a>

                                                        <a href="#" className="people-item">
                                                            <div className="h-avatar">
                                                                {/* <img className="avatar" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/photos/19.jpg" alt="" data-user-popover="17"> */}
                                                                {/* <img className="badge" src="https://via.placeholder.com/150x150" data-demo-src="assets/img/icons/flags/germany.svg" alt=""> */}
                                                            </div>
                                                            <div className="meta">
                                                                <span className="dark-inverted">Greta K.</span>
                                                                <span>Sales Manager</span>
                                                            </div>
                                                        </a>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>

                </div>
            </div>
            
        </div>
	);
};

export default PageContact;