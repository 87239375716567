import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ApiServices from '../../APP/ApiServices';
import SiteHeader from '../Layouts/SiteHeader';

const Services = (props) => {
	const navigate = useNavigate();
	const token = useSelector((state) => state.toolkit.token);

	useEffect(() => {
		if (token === 'unauth') navigate('/auth/login');
	}, []);

	return (
		<div>
			<SiteHeader active="services" />
			<div
				id="edit-profile"
				className="view-wrapper is-webapp"
				data-page-title="გადახდები"
				data-naver-offset="214"
				data-menu-item="#payment"
				data-mobile-item="#home-sidebar-menu-mobile"
			></div>
		</div>
	);
};

export default Services;
