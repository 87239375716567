import React from 'react';

import App from '../../../assets/picture/layouts/app.png';

import nbg from '../../../assets/picture/layouts/nbg-logo.png';
import visa from '../../../assets/picture/layouts/visa-logo.png';
import mc from '../../../assets/picture/layouts/master-cardl-logo.png';
import ae from '../../../assets/picture/layouts/american-express-logo.png';

const Footer = (props) => {
	return (
		<footer className="huro-footer section card mt-50">
            <div className="container">
                <div className="footer-head">
                    <div className="head-text">
                        <h3 className='font'>ჩამოტვირთე</h3>
                        <p className='font-p'>გადაიხადე სერვისები აპლიკაციიდან</p>
                    </div>
                    <div className="head-action">
                        <img src={App} alt="" />
                    </div>
                </div>
                <div className="columns footer-body">
                    <div className="column is-6 ">
                        <div className="columns is-flex-tablet-p">
                            <div className="column">
                                <ul className="footer-column">
                                    <li className="column-item"><a className='font' href="#">ჩვენ შესახებ</a></li>
                                    <li className="column-item"><a className='font' href="#">წესები და პირობები</a></li>
                                    <li className="column-item"><a className='font' href="#">სისტემაში შესსვლა</a></li>
                                </ul>
                            </div>
                            <div className="column">
                                <ul className="footer-column">
                                    <li className="column-item"><a className='font' href="#">კონტაქტი</a></li>
                                    <li className="column-item"><a className='font' href="#">+995 32 3222222</a></li>
                                    <li className="column-item"><a className='font' href="#">info@interpay.ge</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="column is-5 is-offset-2">
                        <ul className="footer-column">
                            <li className="column-item"><a className='font' href="#">პერსონალური მონაცემების დაცვის პოლიტიკა</a></li>
                            <li className="column-item"><a className='font' href="#">პრეტენზიის მიღების განაცხადის ფორმა</a></li>
                            <li className="column-item"><a className='font' href="#"> ხშირად დასმული კითხვები</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className='columns'>
                        <div className="column is-6 has-text-left">
                            <div className="social-links p-t-10 p-b-10 ">
                                <a href="#">
                                    <span className="icon"><i aria-hidden="true" className="fab fa-facebook"></i></span>
                                </a>
                                <a href="#">
                                    <span className="icon"><i aria-hidden="true" className="fab fa-linkedin"></i></span>
                                </a>
                                <a href="" className='ml-4 font-p'> &copy; 2023 | ინტერფეი სერვის ჯგუფი | ყველა უფლება დაცულია. </a>
                            </div>
                        </div>
                        <div className="column is-6 has-text-right">
                            <div className="columns">
                                <div className="column is-3 is-offset-6"><img src={ nbg } alt="" /></div>
                                <div className="column is-1"><img src={ visa } alt="" /></div>
                                <div className="column is-1"><img src={ mc } alt="" /></div>
                                <div className="column is-1"><img src={ ae } alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
	);
};

export default Footer;