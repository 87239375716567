import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import ProfileImage from '../../Layouts/Partials/ProfileImage';

const ProfileNavbar = (props) => {
	const myParams = useParams();
	const pg = myParams.page ? myParams.page : 'data';
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const userBusiness = useSelector((state) => state.toolkit.userBusiness);

	const navBar = [
		{
			name: 'პროფილი',
			type: 'link',
			url: '/profile/data',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			name: 'ორგანიზაციები',
			type: 'link',
			url: '/profile/businesses',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			name: 'შესვლის ისტორია',
			type: 'link',
			url: '/profile/session-history',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			name: 'პარამეტრები',
			type: 'link',
			url: '#',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
	];
	return (
		<div className="column is-4">
			<div className="account-box is-navigation">
				{userBusiness ? (
					<div className="media-flex-center">
						<div className="h-avatar is-large">
							<ProfileImage picsize={50} id={userBusiness.bID} avatar={userBusiness.avatar} business={true} />
						</div>
						<div className="flex-meta">
							<span className="font">
								{userBusiness.short_name} "{userBusiness.legal_name}"
							</span>
							<span className="font-p">{userBusiness.registration_id}</span>
						</div>
					</div>
				) : (
					<div className="media-flex-center">
						<div className="h-avatar is-large">
							<ProfileImage picsize={50} id={userDetails.uID} avatar={userDetails.avatar} business={false} />
						</div>
						<div className="flex-meta">
							<span className="font">
								{userDetails.firstName} {userDetails.lastName}
							</span>
							<span className="font-p">{userDetails.pid}</span>
						</div>
					</div>
				)}
				<div className="account-menu">
					{navBar.map((data, i) => (
						<div key={i}>
							{data.type === 'link' && !data.hide ? (
								<Link
									to={data.url}
									className={
										data.url === '/profile/' + pg
											? data.activeClass
											: data.class
									}
								>
									<i className={data.icon}></i>
									<span className="font">{data.name}</span>
								</Link>
							) : (
								data.type === 'divider' && <div className="dropdown-divider " />
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProfileNavbar;
